import React from "react"
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import get from 'lodash/get'
import BgImage from "../../components/BgImage";
import Reviews from "../../components/section/Reviews";
import ViewWork from "../../components/section/viewWork";
import IntroContainer from "../../components/introContainer"; 

class PatioPage extends React.Component {
  render(data) {

      const page = get(this.props, 'data.allNodeService.edges[0].node');
      const bannerImage = get(this.props, 'data.allNodeService.edges[0].node.relationships.field_banner_image.localFile.childImageSharp.fluid');


      return(
        <Layout>
          <SEO title={page.title} />

          <BgImage title={page.title} fluid={bannerImage} overlayColor="#00000069" height="400px">
            <Container>
              <h1>{page.title}</h1>
            </Container>
          </BgImage>


          <IntroContainer> 
              <div dangerouslySetInnerHTML={{ __html: page.body.processed }} />
          </IntroContainer>

          <ViewWork />

           <Reviews />

        </Layout>
    );
  }
}

export const PatioPageQuery = graphql` 
  query {
    allNodeService(filter: {drupal_internal__nid: {eq: 7}}) {
      edges {
        node {
          title
          body {
            processed
          }
          relationships {
            field_banner_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;


const Container = styled.div`
  height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 780px;
    margin: 50px auto;
    text-align: center;
    flex-direction: column;

    h1,
    h2 {
      color: #fff;
    }

    h2 {
      font-size: 20px;
    }
`

export default PatioPage
